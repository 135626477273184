
import { camera } from 'ionicons/icons';
import { IonButton, IonContent, IonPage, IonIcon } from '@ionic/vue';
import { defineComponent, watch, ref, onUnmounted, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import Canvas from '@/components/wall-image-viewer/Canvas.vue';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { useCanvasWidth } from '@/composables/useCanvasWidth';

export default defineComponent({
  name: 'New',
  components: {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    Canvas,
  },
  setup() {
    const router = useRouter();
    const photoData = ref('');
    const photoUploaded = ref(false);
    const { photo, takePhoto } = usePhotoGallery();
    const { canvasWidth, updateCanvasWidth } = useCanvasWidth();

    const ionContent = ref<typeof IonContent | null>(null);

    watch(photo, (oldPhoto, newPhoto) => {
      if (newPhoto !== oldPhoto && oldPhoto !== null) {
        photoUploaded.value = true;
        if (oldPhoto.data) {
          photoData.value = oldPhoto.data;
        }
        updateCanvasWidth();
      }
    });

    window.addEventListener('resize', updateCanvasWidth);

    onMounted(() => {
      updateCanvasWidth();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateCanvasWidth);
    });

    const handleClickUserGuide = () => {
      router.push({ name: 'Help', hash: '#section2' });
    };

    return {
      canvasWidth,
      photoData,
      photoUploaded,
      photo,
      takePhoto,
      camera,
      ionContent,
      handleClickUserGuide,
    };
  },
});
