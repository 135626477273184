
import Konva from 'konva';
import {
  IonButton,
  IonContent,
  IonItem,
  IonText,
  IonSpinner,
} from '@ionic/vue';
import { defineComponent, inject, onMounted, ref, watch, PropType } from 'vue';
import { useRouter } from 'vue-router';

import getBoundingBoxes, {Box} from '@/components/wall-image-viewer/getBoundingBoxes';
import { useBoxLayer, DrawLayer } from '@/components/wall-image-viewer/box-layer';
import {SelectMode, TapeMode, NumberMode, BoxClass} from '@/components/wall-image-viewer/types';
import {
  addKonvaListenerPinchZoom,
  addKonvaListenerTouchMove,
} from '@/components/wall-image-viewer/stageListeners';
import {convertFileToBase64} from "@/common/utils";

Konva.pixelRatio = 1;

export default defineComponent({
  name: 'Canvas',
  components: {
    IonButton,
    IonItem,
    IonText,
    IonSpinner,
  },
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    ionContent: {
      type: Object as PropType<typeof IonContent>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const selectedMode = ref<SelectMode>(SelectMode.DRAWBOX);
    const tapeMode = ref<TapeMode>(TapeMode.NONE);
    const numberMode = ref<NumberMode>(NumberMode.ON);
    const isLoading = ref(false);
    let image:HTMLImageElement;
    let nnBoxes: Array<Box>;
    let wallImage: string;

    const computeDimensions = (heightToWidthRatio: number) => {
      const windowHeight =
        window.innerHeight && document.documentElement.clientHeight
          ? Math.min(window.innerHeight, document.documentElement.clientHeight)
          : window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName('body')[0].clientHeight;
      const height = Math.min(props.width * heightToWidthRatio, windowHeight * 0.95);
      const width = height * (1 / heightToWidthRatio);
      return { width, height };
    };

    const setRouteImageUri: (imageUri: string) => void = inject(
        'setRouteImageUri',
        () => undefined,
    );

    const setWallBoxList: (boxList: Array<Box>) => void = inject(
      'setWallBoxList',
      () => undefined,
    );

    let stage: Konva.Stage;
    const imageLayer = new Konva.Layer();
    const konvaImage = new Konva.Image({ image: undefined });
    const {
      boxLayer,
      resizeBoxLayer,
      addBoxLayerBoundingBoxes,
      clearBoxLayer,
      resetBoxLayerToUnSelected,
    } = useBoxLayer(selectedMode, tapeMode, numberMode);

    /**
     * Loads the Image and the bounding boxes retrieved from backend.
     */
    const loadImageOnStage = async () => {
      image = new Image();
      // eslint-disable-next-line
      image.addEventListener('load', async function () {
        // Clear all boxes first
        clearBoxLayer();
        // Add image to stage
        const { width, height } = computeDimensions(image.height / image.width);
        stage.width(width);
        stage.height(height);
        konvaImage.setAttrs({ image, width, height });
        // Get new boxes
        const formData = new FormData();
        const imageWidth = this.width;
        const imageBlob = await (await fetch(props.imgSrc)).blob();
        
        wallImage = await convertFileToBase64(imageBlob);
        formData.append('image', imageBlob);
        formData.append('width', imageWidth.toString());
        
        let rawBoundingBoxes: Array<any> = [];
        isLoading.value = true;
        try {
          rawBoundingBoxes = await getBoundingBoxes(formData);
        } finally {
          isLoading.value = false;
        }
        nnBoxes = rawBoundingBoxes;
        addBoxLayerBoundingBoxes(rawBoundingBoxes);
        resizeBoxLayer(width / imageWidth);
        imageLayer.batchDraw();

        // Listeners must be added after image is loaded
        // Currently initial listeners are for SelectMode.DRAWBOX
        addKonvaListenerPinchZoom(stage);
        DrawLayer.addKonvaDrawLayer(stage);
      });
      image.src = props.imgSrc;
    };

    onMounted(async () => {
      stage = new Konva.Stage({
        container: 'konva-container',
      });
      imageLayer.listening(false);
      imageLayer.add(konvaImage);
      stage.add(imageLayer);
      stage.add(boxLayer);

      await loadImageOnStage();
    });

    const resizeStage = () => {
      const { width: newWidth, height: newHeight } = computeDimensions(
        konvaImage.height() / konvaImage.width(),
      );
      const factor = newWidth / konvaImage.width();
      konvaImage.width(newWidth);
      konvaImage.height(newHeight);
      imageLayer.batchDraw();
      stage.width(newWidth);
      stage.height(newHeight);
      resizeBoxLayer(factor);
      if (DrawLayer.isDrawLayerAdded(stage)) {
        DrawLayer.resizeDrawLayer(stage, factor);
      }
    };
    watch(() => props.width, resizeStage);

    const handleReset = () => {
      selectedMode.value = SelectMode.HANDHOLD;
      resetBoxLayerToUnSelected();
    };

    const handlePostClick = () => {
      const reverseFactor = image.width / konvaImage.width();
      const drawBoxes = DrawLayer.getKonvaDrawLayerBoundingBoxes(stage);
      drawBoxes.map(item=>{
        item.class = BoxClass.HOLD;
        item.w *= reverseFactor;
        item.h *= reverseFactor;
        item.x *= reverseFactor;
        item.y *= reverseFactor;
      })
      
      DrawLayer.removeKonvaDrawLayer(stage);
      addKonvaListenerTouchMove(stage, props.ionContent);
      imageLayer.batchDraw();
      
      setWallBoxList([...nnBoxes, ...drawBoxes]);
      setRouteImageUri(wallImage);
      
      router.push({ name: 'UploadWall' });
    };

    const handleUndoDraw = () => {
      if (DrawLayer.isDrawLayerAdded(stage)) {
        DrawLayer.removeKonvaLastDrawnRect(stage);
      }
    };
    
    watch(
      () => props.imgSrc,
      async () => {
        await loadImageOnStage();
      },
    );

    return {
      SelectMode,
      selectedMode,
      handlePostClick,
      handleUndoDraw,
      handleReset,
      isLoading,
    };
  },
});
